<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="clients-create-page-title">
      {{ $t('administration.partitions.create.title') }}
    </h4>

    <div class="card">
      <div class="card-body">
        <div class="form-centered">
          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-client-error"
          >
            {{ error }}
            <span v-if="showGetMore" class="text-success cursor-pointer" @click="goToBilling()">
              {{ $t('administration.users.create.get_more_label') }}
            </span>
          </b-alert>
          <b-form @submit="formSubmit" qid="create-client-form">
            <div class="form-container">
              <b-form-group>
                <label qid="create-client-name-label">
                  {{ $t('administration.partitions.create.name_label') }}
                </label>
                <b-form-input
                  qid="create-user-form-name"
                  type="text"
                  v-model="clientForm.name"
                  required
                  :maxLength="255"
                  :placeholder="$t('administration.partitions.create.name_placeholder')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-client-cvr-label">
                  {{ $t('administration.partitions.create.cvr_label') }}
                </label>
                <b-form-input
                  qid="create-client-form-cvr"
                  type="text"
                  v-model="clientForm.cvr"
                  :placeholder="$t('administration.partitions.create.cvr_label')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-client-street-label">
                  {{ $t('administration.partitions.create.street_label') }}
                </label>
                <b-form-input
                  qid="create-client-form-street"
                  type="text"
                  v-model="clientForm.street"
                  :placeholder="$t('administration.partitions.create.street_label')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-clients-city-label">
                  {{ $t('administration.partitions.create.city_label') }}
                </label>
                <b-form-input
                  qid="create-clients-form-city"
                  type="text"
                  v-model="clientForm.city"
                  :placeholder="$t('administration.partitions.create.city_label')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-client-postcode-label">
                  {{ $t('administration.partitions.create.postcode_label') }}
                </label>
                <b-form-input
                  qid="create-client-form-postcode"
                  type="text"
                  v-model="clientForm.postcode"
                  :placeholder="$t('administration.partitions.create.postcode_label')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-clients-country-label">
                  {{ $t('administration.partitions.create.country_label') }}
                </label>
                <b-form-input
                  qid="create-client-form-country"
                  type="text"
                  v-model="clientForm.country"
                  :placeholder="$t('administration.partitions.create.country_label')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-client-email-label">
                  {{ $t('administration.partitions.create.email_label') }}
                </label>
                <b-form-input
                  type="email"
                  qid="create-client-form-email"
                  v-model="clientForm.email"
                  :placeholder="$t('administration.partitions.create.email_label')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="goToList"
                        qid="create-client-cancel-button"
              >
                {{ $t('administration.users.create.cancel') }}
              </b-button>
              <b-button type="submit"
                        class="mt-3 ml-3"
                        :disabled="buttonDisabled"
                        variant="success"
                        qid="create-client-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('administration.users.create.submit') }}
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'CreateClient',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.title'),
            to: '/maintenance'
          },
          {
            text: this.$t('maintenance.partitions.title'),
            to: '/maintenance/partitions'
          },
          {
            text: this.$t('maintenance.partitions.create.title'),
            active: true
          }
        ],
        clientForm: {
          name: '',
          cvr: '',
          street: '',
          city: '',
          postcode: '',
          country: '',
          email: '',
          client: ''
        },
        error: '',
        buttonDisabled: false,
        showGetMore: false
      }
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
    },
    methods: {
      goToList() {
        this.$router.push('/maintenance/partitions')
      },
      goToBilling() {
        window.location.href = '/client/' + this.getClient.slug + '?tabindex=1';
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();
        this.clientForm.client = this.$store.state.client.slug;

        piincHttp.post('partitions', this.clientForm).then(function(response) {
          self.$toastr('success', self.$t('administration.partitions.create.success'))
          piincHttp.get('users/me').then(function(response) {
            self.user = response.data.user;
            self.clients = response.data.clients;
            self.user.clients = self.clients;
            // store logged in user in store
            self.$store.commit('storeUser', self.user);

          }, function() {});
          self.goToList();
        }, function(error) {
          if (error.status !== 200) {
            self.showGetMore = false
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.message == 'You are not allowed to add partition. Please check subscription.') {
              self.error = self.$t('administration.users.create.subscription_error_partition')
              self.showGetMore = true
            }
          }
        })
      },

    },
    components: {

    },
    created () {

    }
  }
</script>

<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="maintenance-create-user-title">
      {{ $t('maintenance.users.invite_button') }}
    </h4>

    <div class="card">
      <div class="card-body">
        <div class="form-centered">
          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-user-error"
          >
            {{ error }}
          </b-alert>
          <b-form @submit="formSubmit" qid="create-user-form">
            <div class="form-container">
              <b-form-group>
                <label qid="create-user-name-label">
                  {{ $t('administration.users.create.name_label') }}
                </label>
                <b-form-input
                  qid="create-user-form-name"
                  type="text"
                  v-model="userForm.name"
                  required
                  :maxLength="255"
                  :placeholder="$t('administration.users.create.name_placeholder')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-user-email-label">
                  {{ $t('administration.users.create.email_label') }}
                </label>
                <b-form-input
                  qid="create-user-form-email"
                  type="email"
                  v-model="userForm.email"
                  required
                  :placeholder="$t('administration.users.create.email_label')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-user-phone-label">
                  {{ $t('administration.users.create.phone_label') }}
                </label>
                <b-form-input
                  type="text"
                  qid="create-user-form-phone"
                  v-model="userForm.phone"
                  :placeholder="$t('administration.users.create.phone_label')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-user-role-label">
                  {{ $t('administration.users.create.role_label') }}
                </label>
                <multiselect
                    v-model="selectedRole"
                    :options="roles"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('administration.clients.members.select_role_placeholder')"
                    label="name_translated"
                    qid="create-user-form-role"
                ></multiselect>
              </b-form-group>
              <b-alert v-if="selectedRole && (selectedRole.name === 'Client admin' || selectedRole.name === 'Partition admin')"
                       show
                       variant="warning"
                       qid="create-user-error"
              >
                {{ $t('administration.users.create.role_cost_notice') }}
              </b-alert>
              <b-form-group>
                <label qid="create-user-authentication-label">
                  {{ $t('administration.users.create.authentication_label') }}
                </label>
                <b-form-radio-group
                  v-model="userForm.authentication"
                  :options="radioOptions"
                  qid="create-user-form-authentication"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group v-if="userForm.authentication == 'Microsoft' || userForm.authentication == 'Application'">
                <label qid="create-user-upn-email-label">
                  {{ $t('administration.users.create.upn_email_label') }}
                </label>
                <b-form-input
                  qid="create-user-form-upn-email"
                  type="email"
                  v-model="userForm.upn_email"
                  :placeholder="userForm.email"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="goToList"
                        qid="create-user-cancel-button"
              >
                {{ $t('administration.users.create.cancel') }}
              </b-button>
              <b-button type="submit"
                        class="mt-3 ml-3"
                        variant="success"
                        :disabled="buttonDisabled"
                        qid="create-user-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('administration.users.create.submit') }}
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'CreateUser',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.title'),
            to: '/maintenance'
          },
          {
            text: this.$t('maintenance.users.title'),
            to: '/maintenance/users'
          },
          {
            text: this.$t('maintenance.users.invite_button'),
            active: true
          }
        ],
        radioOptions: [
          {text: this.$t(this.$options.filters.slugify('Application')), value: 'Application'},
          {text: this.$t(this.$options.filters.slugify('Microsoft')), value: 'Microsoft'},
          {text: this.$t(this.$options.filters.slugify('Google')), value: 'Google'}
        ],
        userForm: {
          name: '',
          email: '',
          phone: '',
          client:'',
          role: '',
          authentication: '',
          upn_email: ''
        },
        error: '',
        roles: [],
        client:'',
        // Multiselect v-model needs to be set as array in order to work properly
        selectedRole: [],
        buttonDisabled: false
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getClientRoles() {
        return this.$store.state.client.clientRoles
      }
    },
    watch: {
      getClient: function () {
        this.setRoles();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/maintenance/users')
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();
        this.userForm.client = this.getClient.slug;
        this.userForm.role = this.selectedRole.id;
        if (this.userForm.authentication != 'Microsoft' && this.userForm.authentication != 'Application') {
          this.userForm.upn_email = ''
        }
        piincHttp.post('users', this.userForm).then(function(response) {
          self.$toastr('success', self.$t('administration.users.create.success'))
          self.buttonDisabled = false;
          self.goToList();
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.email) {
              self.error = self.error + '\n\n' + error.data.errors.email[0];
            }
          }
        })
      },
      setRoles() {
        let self = this;
        this.roles = []

        if (this.getClient && this.getClient.parent) {
          _.forEach(this.getClientRoles, function(role) {
            if (role.name != 'Client admin'){
              self.roles.push(role)
            }
          })
        } else {
          _.forEach(this.getClientRoles, function(role) {
            if (role.name != 'Partition admin'){
              self.roles.push(role)
            }
          })
        }
        _.forEach(this.roles, function(role) {
          role.name_translated = self.$t('maintenance.users.role.' + self.$options.filters.slugify(role.name))
        })
      }
    },
    components: {

    },
    created () {
      this.setRoles();
      this.userForm.authentication = 'Application'
    }
  }
</script>
